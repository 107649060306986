<template>
  <div class="wrapper">
    <div class="btns">
      <el-button class="button" type="text" @click="goBack" icon="el-icon-back" style="font-size:16px;">返回</el-button>
      <el-button class="button" type="primary" size="small" @click="handleDownload">下载报告</el-button>
    </div>
    <div id="table-con" style="padding:20px 40px 0">
      <h4 class="title">学生学期综合素质评价报告</h4>
      <div class="message">
        {{year}}-{{year+1}}学年 {{semeterObj[semester]}} <el-divider direction="vertical" />
        专业：{{userInfo.majorName}} <el-divider direction="vertical" />
        班级：{{userInfo.className}} <el-divider direction="vertical" />
        学号：{{userInfo.code}} <el-divider direction="vertical" />
        姓名：{{userInfo.realname}}
      </div>
      <el-table
        v-loading="loading"
        :data="paperData"
        :span-method="objectSpanMethod"
        border
        :header-cell-style="{ background: '#eff3ff', color: '#5799f4' }"
        style="width: 100%;">
        <el-table-column
          prop="paper.name"
          label="评价表"
          width="200px"
        />
        <el-table-column
          prop="target1.name"
          label="指标"
          class-name="theadCellClass"
          width="180px"
        />
        <el-table-column
          prop="target2.name"
          label=""
          class-name="theadCellClass"
          width="180px"
        />
        <el-table-column
          prop="target3.name"
          label=""
        />
        <el-table-column
          label="等级评定/分值"
          width="120px"
          align="center"
        >
          <strong slot-scope="scope">{{scope.row.score}}</strong>
        </el-table-column>
      </el-table>
      <div class="total">评价表均分：<strong>{{paperScore}}</strong></div>
      <div class="content-table">
        <div style="width:200px" class="th-div"><strong>综合素质写实记录</strong></div>
        <div class="td-div">
          <ul v-if="awardData.length || punishmentData.length">
            <li v-for="item in awardData" :key="item.id">
              项目：{{item.projectName}} <el-divider direction="vertical" />
              级别：{{item.type}} <el-divider direction="vertical" />
              主办单位：{{item.organizer}} <el-divider direction="vertical" />
              获奖日期：{{item.time}} <el-divider direction="vertical" />
              名次：{{item.rank}} <el-divider direction="vertical" />
              加分：<strong>{{item.score}}</strong>
            </li>
            <li v-for="item in punishmentData" :key="item.id">
              违规：{{item.projectName}} <el-divider direction="vertical" />
              类别：{{item.type}} <el-divider direction="vertical" />
              违规日期：{{item.time}} <el-divider direction="vertical" />
              扣分：<strong>{{item.score}}</strong>
            </li>
            <li>合计：<strong>{{recordScore}}</strong>分</li>
          </ul>
          <ul v-else>
            <li style="text-align: center">无记录数据</li>
          </ul>
        </div>
      </div>
      <div class="level-list">
        <div style="width:200px"><strong>综合素质等级评定</strong></div>
<!--        <div style="text-align: center"><strong>优秀</strong>（优秀  良好  合格  不合格）</div>-->
        <div>总得分：<strong>{{+paperScore + +recordScore}}分</strong></div>
      </div>
      <div class="write-table">
        <div style="width:200px" class="th-div"><strong>综合素质评价意见</strong></div>
        <div class="td-div">
          <div class="td-div-item">
            <div class="type" style="width:180px"><strong>学生自我评价</strong></div>
            <div class="write">
              <span class="name">学生（签名）：</span>
              <span>年</span>
              <span>月</span>
              <span>日</span>
            </div>
          </div>
          <div class="td-div-item">
            <div class="type" style="width:180px"><strong>班主任评价</strong></div>
            <div class="write">
              <span class="name">班主任（签名）：</span>
              <span>年</span>
              <span>月</span>
              <span>日</span>
            </div>
          </div>
          <div class="td-div-item">
            <div class="type" style="width:180px"><strong>政教处意见</strong></div>
            <div class="write">
              <span class="name">政教处（盖章）：</span>
              <span>年</span>
              <span>月</span>
              <span>日</span>
            </div>
          </div>
        </div>
      </div>
      <div class="parents-table">
        <div class="th-div" style="width:401px"><strong>家长反馈意见</strong></div>
        <div class="td-div"></div>
      </div>
    </div>
  </div>
</template>

<script>
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'

export default {
  data() {
    return {
      loading: false,
      userInfo: {},
      stuId: JSON.parse(localStorage.getItem("ss-userInfo")).id,
      year: this.$route.query.year,
      semester: this.$route.query.semester,
      paperData: [], // 评价表数据
      paperScore: this.$route.query.paperScore, // 评价表分数
      awardData: [], // 获奖数据
      punishmentData: [], // 违规数据
      recordScore: 0, // 写实记录合计分数
      semeterObj: {1:'第一学期',2:'第二学期'},
      url:{
        // 评价表
        paperData: '/evas/statistics/report/evasPaperReport',
        // 写实记录
        recordData: '/evas/statistics/report/evasRecordsReport'
      }
    }
  },
  mounted(){
    // 加载表数据
    this.getTable()
    // 写实记录
    this.getRecordData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    handleDownload(){
      html2Canvas(document.querySelector('#table-con'), {
        allowTaint: true
      }).then(function (canvas) {
        let contentWidth = canvas.width
        let contentHeight = canvas.height
        let pageHeight = contentWidth / 592.28 * 841.89
        let leftHeight = contentHeight
        let position = 0
        let imgWidth = 595.28
        let imgHeight = 592.28 / contentWidth * contentHeight
        let pageData = canvas.toDataURL('image/jpeg', 1.0)
        let PDF = new JsPDF('', 'pt', 'a4')
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= 841.89
            if (leftHeight > 0) {
              PDF.addPage()
            }
          }
        }
        PDF.save('学生学期综合素质评价报告.pdf')
        }
      )
    },
    // 评价表数据
    getTable(){
      this.loading = true
      let params = {stuId:this.stuId, year:this.year, semester:this.semester}
      this.$get(this.url.paperData,params).then(res=>{
        if(res.success){
          let data = res.result
          this.paperData = data.paper.list
          // 评价得分
          // this.paperScore = data.paper.total
          this.userInfo = data.student
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    // 获奖数据
    getRecordData(){
      let params = {stuId:this.stuId, year:this.year, semester:this.semester}
      this.$get(this.url.recordData,params).then(res=>{
        if(res.success){
          let data = res.result
          this.awardData = data.award
          this.punishmentData = data.punishment
          this.recordScore = data.total
        }
      })
    },
    // 合并单元格
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if(columnIndex!==4){
        let name = column['property'].split('.')
        let rowspan = row[name[0]]['rowspan']
        let colspan = row[name[0]]['colspan']
        return {
          rowspan: rowspan,
          colspan: colspan
        }
      }
    }
  },

}
</script>

<style lang="scss" scoped>
.wrapper{
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  min-height: 800px;
}
.btns{
  display: flex;
  align-items: center;
  justify-content:space-between;
}
.title{
  text-align: center;
  color: #333;
  font-size: 18px;
  padding: 10px 0;
}
.message{
  text-align: center;
  font-size: 16px;
  color: #666;
}
.el-table{
  margin-top: 20px;
  color: #000;
}
.total{
  font-size: 14px;
  color: #606266;
  padding: 12px 10px;
  min-height: 48px;
  border: 1px solid #c1c1c1;
  border-top: 0;
  box-sizing: border-box;
  text-align: right;
}
.content-table{
  display: flex;
  align-items: center;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  margin-top: -1px;
  font-size: 14px;
  color: #000;
  .th-div{
    padding: 0 10px;
    text-align: center;
  }
  .td-div{
    flex: 1;
    border-left: 1px solid #c1c1c1;
    li{
      padding:12px 10px;
      margin-bottom: 0;
    }
    li+li{
      border-top: 1px solid #c1c1c1;
    }
  }
}
.level-list{
  display: flex;
  align-items: center;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  margin-top: -1px;
  font-size: 14px;
  color: #000;
  div:first-child{
    text-align: center;
  }
  div{
    padding: 12px 10px;
  }
  div:nth-child(2){
    flex: 1;
  }
  div+div{
    border-left: 1px solid #c1c1c1;
  }
}
.write-table{
  display: flex;
  align-items: center;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  margin-top: -1px;
  font-size: 14px;
  color: #000;
  .th-div{
    padding: 0 10px;
    text-align: center;
  }
  .td-div{
    flex: 1;
    border-left: 1px solid #c1c1c1;
    .td-div-item{
      margin-bottom: 0;
      min-height: 200px;
      display: flex;
      div{
        padding:12px 10px;
      }
      .type{
        border-right: 1px solid #c1c1c1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .write{
        flex: 1;
        display: flex;
        align-items: end;
        justify-content: flex-end;
        .name{
          margin-right: 90px;
        }
        span{
          margin: 0 20px;
        }
      }
    }
    .td-div-item+.td-div-item{
      border-top: 1px solid #c1c1c1;
    }
  }
}
.parents-table{
  display: flex;
  align-items: center;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  margin-top: -1px;
  font-size: 14px;
  color: #000;
  .th-div{
    padding: 12px 10px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .td-div{
    height: 140px;
    border-left: 1px solid #c1c1c1;
  }
}
/deep/ .el-table--border{
  border: 1px solid #c1c1c1;
  &::before,
  &::after{
    background-color: #c1c1c1;
  }
  .el-table__cell{
    border-right: 1px solid #c1c1c1;
  }
  thead .theadCellClass{
    border-right: 1px solid rgba(0,0,0,0);
  }
  th.el-table__cell.is-leaf{
    border-bottom: 1px solid #c1c1c1 !important;
  }
  td.el-table__cell{
    border-bottom: 1px solid #c1c1c1 !important;
    border-right: 1px solid #c1c1c1 !important;
  }
}
</style>
